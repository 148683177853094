import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import {
    Container,
    TwoColumns,
    Sidebar,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../components/InnerPage';

import Breadcrumbs from '@bahaide/shared/components/Breadcrumbs';

const ArticleTemplate = ({ data }) => {
    const { markdownRemark } = data;
    const { frontmatter, html, excerpt, fields } = markdownRemark;
    // Null value possible
    const article_image =
        frontmatter.hasOwnProperty('thumbnail_image') &&
        frontmatter.thumbnail_image !== null
            ? frontmatter.thumbnail_image
            : frontmatter.feature_image;

    return (
        <InnerPage
            pageType="single-post"
            title={frontmatter.title}
            description={excerpt}
            image={article_image && article_image.publicURL}
        >
            <div className="intro-holder">
                <Container>
                    <Breadcrumbs currentItem={frontmatter.title} />
                    <h1>{frontmatter.title}</h1>
                    <div className="meta-info">
                        <time>{frontmatter.date}</time>
                    </div>
                    {article_image && (
                        <div className="img-holder">
                            <Img
                                alt=""
                                width="480"
                                height="275"
                                fluid={article_image.childImageSharp.fluid}
                                className="attachment-full size-full"
                            />
                            {frontmatter.feature_image_caption && (
                                <figcaption className="p-4 text-sm">
                                    {frontmatter.feature_image_caption}
                                </figcaption>
                            )}
                        </div>
                    )}
                </Container>
            </div>

            <TwoColumns>
                <div id="content" dangerouslySetInnerHTML={{ __html: html }} />
                <Sidebar>
                    <h3>TEILEN</h3>
                    <ul className="mb-4">
                        <li>
                            <OutboundLink
                                target="_blank"
                                href={`https://www.facebook.com/sharer/sharer.php?u=https://aktuelles.bahai.de/artikel${fields.slug}`}
                            >
                                <i className="mr-4 fa fa-facebook"></i>Facebook
                            </OutboundLink>
                        </li>
                        <li>
                            <OutboundLink
                                target="_blank"
                                href={`https://twitter.com/intent/tweet?text=${frontmatter.title} - ${excerpt}&url=https://aktuelles.bahai.de/artikel${fields.slug}&`}
                            >
                                <i className="mr-2 fa fa-twitter"></i>Twitter
                            </OutboundLink>
                        </li>
                    </ul>
                </Sidebar>
            </TwoColumns>
        </InnerPage>
    );
};

export default ArticleTemplate;

export const articlePageQuery = graphql`
    query articleQuery($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            excerpt(pruneLength: 100, format: PLAIN)
            fields {
                slug
            }
            frontmatter {
                date(formatString: "DD. MMMM YYYY", locale: "de")
                title
                feature_image {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 1024, quality: 80) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                thumbnail_image {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 1024, quality: 80) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                feature_image_caption
            }
        }
    }
`;
